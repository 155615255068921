
function Dropdownlanguage() {
    if (document.getElementById('header')) {
        var btnDropDown = document.querySelector('.header .header__nav .header__bottom .header__language .header__selectlanguage');
        var navLanguage = document.querySelector('.header .header__nav .header__bottom .header__language .header__selectlanguage .header__navLanguage');
        btnDropDown.addEventListener('click', function (item) {
            navLanguage.classList.toggle('active');

        })
    }
}
function DropdownSearch() {
    if (document.getElementById('header')) {
        var btnDropDown = document.querySelector('.header .header__nav .header__bottom .header__search .btnsearch');
        var navSearch = document.querySelector('.header .header__nav .header__bottom .header__search  .header__search--input');
        btnDropDown.addEventListener('click', function (item) {
            // if (btnDropDown.classList.contains('active')) {
            //     navSearch.classList.remove('active');

            // } else {
            //     navSearch.classList.add('active');
            //     console.log("11")
            // }
            navSearch.classList.toggle("active")
        })
    }
}
function handleTextSearch() {
    if (document.getElementById('header')) {
        var searchBox1 = <HTMLInputElement>document.getElementById('searchBox');

        var btnSubmit = <HTMLInputElement>document.getElementById('submit');

        btnSubmit.addEventListener('click', function (e) {
            e.preventDefault();

            var valSeacrchBox1 = searchBox1.value;


            submitSearch(valSeacrchBox1);

        })
    }
}
function submitSearch(searchBox1: string) {
    var infor1 = {
        SeacrchBox1: searchBox1,

    };
    // gọi ajax
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {


        }
    };
    xhttp.open("POST", "/infor-search", true);
    // đổi ojbectt thành chuỗi
    xhttp.send(JSON.stringify(infor1));
    // khi gọi thành công 
    // inner.innerHTML =this.responseText;
    // product1();


    // khi thất bại
    console.log('thất bại');


}

function openNavMobile (){
    const header = document.getElementById("header")
    if(header){
        const bthNav = header.querySelector(".header__humberger")
        const bthNavClose = header.querySelector(".btn-close_nav")
        const navMoblie = header.querySelector(".header-nav_mobile")
        const navMoblieSection = header.querySelector(".header-nav_mobile .header-nav_mobile-section")
        if(bthNav){
            bthNav.addEventListener("click",()=>{
                navMoblie.classList.add('active')
                navMoblieSection.classList.add('active')
                document.body.style.overflow="hidden"
            })
        }
        if(bthNavClose){
            bthNavClose.addEventListener("click",()=>{
                
                navMoblie.classList.remove('active')
                navMoblieSection.classList.remove('active')
                document.body.style.overflow="auto"
            })
        }
        
    }
}

function toggleLink (){
    const header = document.getElementById("header")
    if(header){
        const bthNav = header.querySelectorAll(".list-link__items")
        
        bthNav.forEach(function(element){
            const linkName = element.querySelector(".link")
            const sectionLink = element.querySelector(".list-link__items--more") 
            if(sectionLink){
                linkName.addEventListener('click',()=>{
                    sectionLink.classList.add("active")
                })
                
            }
            const btn_close = element.querySelector(".btn_close--nav")
            if(btn_close){
                btn_close.addEventListener('click',()=>{
                    sectionLink.classList.remove("active")
                })
                
            }
        })
        
    }
}

function setPaddingInfor(){
    const header = document.getElementById("header")
    const banner = document.getElementById("banner")
    const headerHeight = header as HTMLElement
        const height = headerHeight.offsetHeight
    if(banner){
        banner.style.paddingTop = "calc(" + height +"px + 5px)"
    }
    else{
        var main = document.getElementsByTagName('main')[0]
        main.style.paddingTop = "calc(" + height +"px + 30px)"
    }
  }

  window.onresize = displayWindowSize;
  window.onload = displayWindowSize;
  function displayWindowSize() {
      var myWidth = window.innerWidth;
      if(myWidth){
        setPaddingInfor()
      }
      
  };
  
export const header = function () {
    Dropdownlanguage();
    DropdownSearch();
    handleTextSearch();
    openNavMobile();
    toggleLink();
    setPaddingInfor();
}