import {swi} from "../components/swiper";
declare var Swiper: any;
function idx5(){
    var qSwiper = <HTMLElement>document.querySelector('.index-5-swiper');
    if (qSwiper){
        const nSwiper = new Swiper(`.swiper-idx5`, {
            slidesPerView: 3,
            spaceBetween: 20,
            // loop: true,

            navigation: {
                nextEl: `.index-5-swiper .swiper-button-custom-next`,
                prevEl: `.index-5-swiper .swiper-button-custom-prev`,
            },
            breakpoints:{
                200: {
                    slidesPerView: 1,
                    spaceBetween: 8,
                },
                575:{
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                1199:{
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1399:{
                    slidesPerView: 3,
                    spaceBetween: 20,
                },

            },
        })
    }

    // const nSwiper = new Swiper(`.index-5-swiper .Myindex5 `, {
    //     slidesPerView: 3,
    //     spaceBetween: 20,

    //     navigation: {
    //         nextEl: ".index-5-swiper .swiper-button-custom-next",
    //         prevEl: ".index-5-swiper .swiper-button-custom-prev"
    //     }
    // })
}
function idx6(){
    var qSwiper6 = <HTMLElement>document.querySelector('.index-6-swiper');
    if (qSwiper6){
        const nSwiper = new Swiper(`.swiper-idx6`, {
            slidesPerView: 5,
            spaceBetween: 20,
            loop: true,
            autoplay: true,
            navigation: {
                nextEl: `.index-6-swiper .swiper-button-custom-next`,
                prevEl: `.index-6-swiper .swiper-button-custom-prev`,
            },
            breakpoints:{
                200: {
                    slidesPerView: 1,
                    spaceBetween: 8,
                },
                575:{
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 8,
                },
                1199:{
                    slidesPerView: 4,
                    spaceBetween: 8,
                },
                1399:{
                    slidesPerView: 5,
                    spaceBetween: 8,
                },

            },
        })
    }
}
export const index = function(){
    idx5();
    idx6();
    
}