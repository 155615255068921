declare var Swiper: any;


function intro3(){
    var qSwiper = <HTMLElement>document.querySelector('.intro-3-swiper');
    if (qSwiper){
        const nSwiper = new Swiper(`.swiper-int3`, {
            slidesPerView: 2,
            spaceBetween: 0,
            // loop: true,

            navigation: {
                nextEl: `.intro-3-swiper .swiper-button-custom-next`,
                prevEl: `.intro-3-swiper .swiper-button-custom-prev`,
            },
            breakpoints:{

                575:{
                    slidesPerView: 2,
                    spaceBetween: 0,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                },
                1199:{
                    slidesPerView: 3,
                    spaceBetween: 0,
                },
                1399:{
                    slidesPerView: 4,
                    spaceBetween: 0,
                },

            },
        })
    }
}
 
function intro5(){
    if(document.querySelector(".intro-5")){
        const nSwiper = new Swiper(`.intro5-swiper .Myintro5`, {
            slidesPerView: 3,
            spaceBetween: 20,
            loop: true,
            autoplay:true,
            navigation: {
                nextEl: ".intro-5 .intro-5__content .swiper-button-custom-next",
                prevEl: ".intro-5 .intro-5__content .swiper-button-custom-prev"
            },
            breakpoints: {
                200:{
                    slidesPerView: 1,
                    spaceBetween: 8
                },
                540:{
                    slidesPerView: 2,
                    spaceBetween: 8
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                1080:{
                    slidesPerView: 3,
                    spaceBetween: 20
                }
            }
        })
    }
    
}
export const intro = function(){
    intro3();
    intro5();
}