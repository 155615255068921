import {swi} from "../components/swiper";
declare var Swiper: any;
function cpd6(){
    var qSwiper6 = <HTMLElement>document.querySelector('.capacity-6-swiper');
    if (qSwiper6){
        
        var nSwiper2 = new Swiper(".swiper-cpd6-thumb", {
            spaceBetween: 12,
            slidesPerView: 3,
            direction: "vertical",
            navigation: {
            },
            on: {
                init: function () {
                    
                },
                transitionStart: function () {
                  
                   
          
                },
                transitionEnd: function () {
                   
                }
            }
          });
        const nSwiper = new Swiper(`.swiper-cpd6`, {
            slidesPerView: 1,
            spaceBetween: 8,
            // loop: true,
            // autoplay: true,
            
            navigation: {
                nextEl: `.swiper-button-custom-next`,
                prevEl: `.swiper-button-custom-prev`,
            },
            
            thumbs: {
                swiper: nSwiper2,
            },
            breakpoints:{
                // 200: {
                //     slidesPerView: 1,
                //     spaceBetween: 8,
                // },
                // 575:{
                //     slidesPerView: 2,
                //     spaceBetween: 8,
                // },
                // 991: {
                //     slidesPerView: 3,
                //     spaceBetween: 8,
                // },
                // 1199:{
                //     slidesPerView: 4,
                //     spaceBetween: 8,
                // },
                // 1399:{
                //     slidesPerView: 5,
                //     spaceBetween: 8,
                // },

            },
            

        });
       
    }
}
function cpd3(){
    var qSwiper = <HTMLElement>document.querySelector('.capacityDetail-3-swiper');
    if (qSwiper){
        const nSwiper = new Swiper(`.swiper-cdp3`, {
            slidesPerView: 3,
            spaceBetween: 20,
            // loop: true,

            navigation: {
                nextEl: `.capacityDetail-3-swiper .swiper-button-custom-next`,
                prevEl: `.capacityDetail-3-swiper.swiper-button-custom-prev`,
            },
            breakpoints:{
                200: {
                    slidesPerView: 1,
                    spaceBetween: 8,
                },
                575:{
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                1199:{
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1399:{
                    slidesPerView: 3,
                    spaceBetween: 20,
                },

            },
        })
    }

    // const nSwiper = new Swiper(`.index-5-swiper .Myindex5 `, {
    //     slidesPerView: 3,
    //     spaceBetween: 20,

    //     navigation: {
    //         nextEl: ".index-5-swiper .swiper-button-custom-next",
    //         prevEl: ".index-5-swiper .swiper-button-custom-prev"
    //     }
    // })
}
function poupContact(){
    if(document.getElementById('capacityDetail-1')){
        var btnContactPoup = document.querySelector('.capacityDetail-1 .capacityDetail-1__content .capacityDetail-1__infor--btn')
        var ContactPoup =  document.querySelector('.capacityDetail-1 .poup-contact')  
        var btnClosePoup = document.querySelector('.capacityDetail-1 .poup-contact .poup-contact__close')
        console.log(ContactPoup);
        btnContactPoup.addEventListener('click', ()=>{
            ContactPoup.classList.add('active');
            document.body.style.overflow = 'hidden';
        })
        btnClosePoup.addEventListener('click', ()=>{
            ContactPoup.classList.remove('active');
            document.body.style.overflow = '';
        })
    }
}
export const capacityDetail = function(){
    cpd6();
    cpd3();
    poupContact();
}