import pagination from "../layout/pagination";
function searchPanigation(){
    if(document.getElementById('search-1')){
        var options ={
            queryClassPagination: "#search-1 .search-1-wrapper .box-pagination",
            listBox:"#search-1 .search-1-wrapper .card02",
            itemPages: 9,
    
        }
        pagination.pagination(options);
    }
    
}
export const search = function(){
    searchPanigation();
}