declare var gsap : any;
declare var Parallax : any;
declare var Swiper : any;
// const Parallax = require('parallax-js')




var swiper = new Swiper("#annerSW", {
    spaceBetween: 30,
    slidesPerView: 1,
    pagination: {
      el: ".swiper-pagination",
      dynamicBullets: true,
    },
    grabCursor: true,
    speed:1500,
    autoplay: true
   
})
export const banner = function(){
//   gettotalLength();
    // hoverPlace();
   
}