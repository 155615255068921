// https://fancyapps.com/docs/ui/fancybox
// https://www.npmjs.com/package/axios
// https://greensock.com/
// https://swiperjs.com/get-started

declare var Swiper : any;
declare var $ : any;
declare var AOS : any;
declare var gsap : any;
declare var anime : any;


// AOS.init({
//   once: true
// });

// import axios from "axios";

//////////////////////////////////////////////////////////
// Common function

// Layout
import {header} from "./layout/header";
import {banner} from "./layout/banner";
// Page
import {index} from "./pages/index";
import {search} from "./pages/search";
import {intro} from "./pages/intro";
import {capacityDetail} from "./pages/capacity-detail";

///////////////////////////////////////////////////////////////
// function Common
function common(){
}

function layout(){
  header();
  banner();
}

// function Page
function page(){
  // ----- add page in here ----- //
  capacityDetail();
  index();
  intro();
  search();
}

/////////////////////WEB NORMAL///////////////////////////
window.addEventListener('load', function() {
  common();
  page()
  layout();
})